import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import AreaAtuacao from "../components/areaatuacao"
import { StaticQuery, graphql } from "gatsby"

const Marcas = () => (
  <div className="tree">
    <ul className="clearfix">
      <li className="setcorp">
        <span title="Sectorp">
          <img
            src="setcorp.svg"
            className="logo--setcorp"
            alt="Setcorp"
            height="50"
            title="Setcorp"
          />
        </span>
        <ul>
          <li className="revobrasil">
            <a
              href="https://www.revobrasil.com.br"
              title="Revo Brasil"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="logo--revobrasil"
                src="revobrasil-negocios-imobiliarios.png"
                alt="Revo Negocios Imobiliários"
                title="Revo Negocios Imobiliários"
              />
            </a>
            <ul className="vertical" />
          </li>
          <li className="setparengenharia">
            <span>
              <img
                className="logo--setparengenharia"
                src="setpar-engenharia.png"
                alt="Setpar Engenharia"
                title="Setpar Engenharia"
              />
            </span>
          </li>
          <li className="setparempreendimentos">
            <a href="https://www.setpar.com.br" title="Setpar Empreendimentos">
              <img
                className="logo--setparempreendimentos"
                src="setpar.svg"
                alt="Setpar Empreendimentos"
                title="Setpar Empreendimentos"
              />
            </a>
          </li>
          <li className="setcasa">
            <a
              href="https://www.setcasa.com.br"
              title="Setcasa"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="logo--setcasa"
                src="setcasa.png"
                alt="Setcasa"
                title="Setcasa"
              />
            </a>
            <ul>
              <li className="setfacil">
                <a
                  href="https://www.setcasa.com.br"
                  title="SetFácil"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="logo--setfacil"
                    src="setfacil.png"
                    alt="SetFácil"
                    title="SetFácil"
                  />
                </a>
              </li>
            </ul>
          </li>
          <li className="setagronegocio">
            <span>
              <img
                className="logo--setagronegocio"
                src="setagronegocio.png"
                alt="Set Agronegócios"
                title="Set Agronegócios"
              />
            </span>
          </li>
        </ul>
      </li>
    </ul>
  </div>
)

const IndexPage = ({ destaque, subdestaque }) => (
  <Layout>
    <SEO title="Setcorp" />

    <section className="bg-white py-5">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-xl-10 offset-xl-1">
            <div className="row">
              <div className="col-12 col-xl-6">
                <h1 className="h2">{destaque.titulo}</h1>
                <p dangerouslySetInnerHTML={{ __html: destaque.texto }} />
                <h2 className="h3">{subdestaque.titulo}</h2>
                <p dangerouslySetInnerHTML={{ __html: subdestaque.texto }} />
              </div>
              <div className="col-12 col-xl-6">
                <Marcas />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <AreaAtuacao />
  </Layout>
)

const IndexPageQuery = () => (
  <StaticQuery
    query={graphql`
      query {
        page: dataJson {
          subdestaque {
            texto
            titulo
          }
          destaque {
            texto
            titulo
          }
        }
      }
    `}
    render={data => (
      <IndexPage
        destaque={data.page.destaque}
        subdestaque={data.page.subdestaque}
      />
    )}
  />
)

export default IndexPageQuery
