import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle, logo }) => (
    <nav className="navbar navbar-expand-lg navbar-light bg-white border-bottom">
        <div className="container-fluid">
            <a className="navbar-brand" href="/" title="Voltar para o início">
                <img src="setcorp.svg" alt={siteTitle} height="40" title="Setcorp" />
            </a>
            <ul className="navbar-nav">
                <li className="nav-item active">
                    <a href="mailto:grupo@setcorp.com.br" title="Enviar email para a Setcorp" className="nav-link text-primary">grupo@setcorp.com.br</a>
                </li>
            </ul>
        </div>
    </nav>
)

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
