import React from "react"
import { StaticQuery, graphql } from "gatsby"

const AreaAtuacao = ({areasatuacao, marcas}) => (
    <section className="py-5">
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 col-xl-10 offset-xl-1">
                    <div className="row">
                        {areasatuacao.map((item, index) => (
                            <div className="col-12 col-md-4 mb-3" key={index}>
                                <div className="h-100 card border-0 shadow-sm">
                                    <div className="card-body">
                                        <h4 className="d-flex h5 mt-0">
                                            {item.icon && (
                                                <img className="d-block" height="45" src={item.icon.publicURL} alt={item.titulo} />
                                            )}
                                            <span className="ml-2 d-block">{item.titulo}</span>
                                        </h4>
                                        <p className="mb-3" dangerouslySetInnerHTML={{ __html: item.texto }} />
                                        {item.tipomarca && (
                                            <ul className="list-unstyled d-flex areaatuacao__lista-marcas flex-wrap">
                                                {marcas.filter(marca => marca.tipo === item.tipomarca ).map((marca, index) => (
                                                    <li key={index} className="mr-3 mb-2">
                                                        {marca.link ? (
                                                            <a href={marca.link} title={marca.nome} target="_blank" rel="noopener noreferrer">
                                                                <img src={marca.logo} alt={marca.nome} />
                                                            </a>
                                                        ):(
                                                            <span>
                                                                <img src={marca.logo} alt={marca.nome} />
                                                            </span>
                                                        )}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
        <footer className="text-center text-muted mt-1">
            <small>©{(new Date()).getFullYear()} Grupo Setcorp - Todos os direitos reservados</small>
        </footer>
    </section>
)

const AreaAtuacaoQuery = () => (
    <StaticQuery
        query={graphql`
      query {
        page: dataJson {
            areasatuacao {
              texto
              titulo
              tipomarca
              icon {
                publicURL
              }
            }
            marcas {
                link
                logo
                nome
                tipo
              }
          }
      }
    `}
        render={data => (
            <AreaAtuacao areasatuacao={data.page.areasatuacao} marcas={data.page.marcas} />
        )}
    />
)

export default AreaAtuacaoQuery